import { createSelector } from "reselect";
import { version } from "../../package.json";
import { apiserver } from "../api/apiServer";

const PREFIX = version + "_";
const SHOP_FETCH_META = `${PREFIX}SHOP_FETCH_META`;
const SHOP_SET_CART_DATA = `${PREFIX}SHOP_SET_CART_DATA`;
const SHOP_SET_DEFAULT_CART_DATA = `${PREFIX}SHOP_SET_DEFAULT_CART_DATA`;
const SHOP_SET_RECENT_ORDER = `${PREFIX}SHOP_SET_RECENT_ORDER`;

export const getReducerName = () => PREFIX + "shopReducer";
export const getShopReducerState = (state) => state[getReducerName()];

export const initialState = {
  meta: {
    updateCartTimer: null,
    updatePositionTimer: null,
    updateCartIntervall: 300,
    cartProgress: 0,
    cadSelection: [],
    configuration: {
      id: undefined,
      data: undefined
    },
    paymentOptions: {
      Invoice:{
        value: "Invoice",
        label: "invoice",
      },
    },
    deliveryOptions: {
      StandardDelivery:{
        value: "StandardDelivery",
        label: "defaultDelivery",
      }
      /* SelfPickUp:{
        value: "SelfPickUp",
        label: "pickup",
      }, */
    },
  },
  cartData: [],
  defaultCartData: {},
  recentOrder: {},
};
// GETTERS
export const getCartData = createSelector(
  getShopReducerState,
  (shopReducer) => shopReducer.cartData
);
export const getDefaultCart = createSelector(
  getShopReducerState,
  (shopReducer) => shopReducer.defaultCartData
);
export const getMeta = createSelector(
  getShopReducerState,
  (shopReducer) => shopReducer.meta
);
export const getRecentOrder = createSelector(
  getShopReducerState,
  (shopReducer) => shopReducer.recentOrder
);
// SETTERS
export function setMeta(data) {
  return {
    type: SHOP_FETCH_META,
    data,
  };
}
export function setRecentOrder(data) {
  return {
    type: SHOP_SET_RECENT_ORDER,
    data,
  };
}
export function setCartData(data) {
  return {
    type: SHOP_SET_CART_DATA,
    data,
  };
}
export function setDefaultCartData(data) {
  return {
    type: SHOP_SET_DEFAULT_CART_DATA,
    data,
  };
}
// HANDLERS
export function updateMeta(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const newMeta = {...state.meta, ...data}

    dispatch(setMeta(newMeta));
  };
}
export function fetchShoppingCarts() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.cart.getCartData();
    } catch (e) {
      console.log({ e });
      return;
    }

    dispatch(setCartData(response.data));
  };
}
export function fetchDefaultShoppingCart() {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.cart.getDefaultCart();
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(setDefaultCartData(response.data));
  };
}
export function fetchSingleShoppingCart(cartId) {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.cart.getSingleCartData(cartId);
    } catch (e) {
      console.log({ e });
      return;
    }

    dispatch(setCartData(response.data));
  };
}
export function submitNewShoppingCart(query) { // creates a new shopping cart
  return async (dispatch, getState) => {

    const state = getState()[getReducerName()];

    let response = undefined;
    try {
      response = await apiserver.cart.submitCartData(query);
    } catch (e) {
      console.log({ e });
      return;
    }

    dispatch(setCartData([...state.cartData, response.data]))
    if (query.setAsDefault === true) {
      dispatch(setDefaultCartData(response.data));
    }
  };
}
export function saveShoppingCart() {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCart = state.defaultCartData;

    try {
       await apiserver.cart.saveCart({
        cartId: defaultCart.id,
        name: defaultCart.name || defaultCart.id,
      });
    } catch (e) {
      console.log({ e });
      return;
    }
  /* dispatch(setCartData(response.data)); */
  };
}
export function loadShoppingCart(data) {
  return async (dispatch) => {
    let response = undefined;
    
    try {
      response = await apiserver.cart.loadCart(data.file);
    } catch (e) {
      if (e.response.data.message === 'NOT_ALLOWED_FILE') {
        data?.callback({ status: "error", err: 'NOT_ALLOWED_FILE' });
      }
      if (e.response.data.message === 'NOT_SUPPORTED_FILE') {
        data?.callback({ status: "error", err: 'NOT_SUPPORTED_FILE' });
      }
      data?.callback({ status: "error", error: e });
      return;
    }
    data?.callback({ status: "success" });
  
    dispatch(setDefaultCartData(response.data));
  };

}
export function importPartsList(data) {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.cart.importPartsList(data.list);
    } catch (e) {
      console.log({ e });
      data?.callback({ status: "error", error: e });
      return;
    }
    data?.callback({ status: "success" });

    dispatch(setDefaultCartData(response.data));
  };
}

export function submitOrder(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCartData = state.defaultCartData;

    let response = undefined;
    try {
      response = await apiserver.purchasedOrder.submitOrder({
        cartId: defaultCartData.id,
        orderType: data.type,
      });
    } catch (e) {
      console.log({ e });
      data.callback({ response, e });
      return;
    }
    data.callback({ response, e:null });
    dispatch(setRecentOrder(response.data));
    dispatch(fetchDefaultShoppingCart());
    //dispatch(setCartData(response.data));
  };
}



export function updateShoppingCartData(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCartData = state.defaultCartData;
    const meta = state.meta;

    const requestBody = {
      cartId: defaultCartData.id,
    };
    const cartOptions = {};
    if (
      typeof data.customerOrderNumber === "string" ||
      defaultCartData.customerOrderNumber
    ) {
      requestBody.customerOrderNumber =
        typeof data.customerOrderNumber === "string"
          ? data.customerOrderNumber
          : defaultCartData.customerOrderNumber;
    }

    if (
      typeof data.commission === "string" ||
      defaultCartData.commission
    ) {
      requestBody.commission =
        typeof data.commission === "string"
          ? data.commission
          : defaultCartData.commission;
    }

    if (
      typeof data.comment === "string" ||
      defaultCartData.comment
    ) {
      requestBody.comment =
        typeof data.comment === "string"
          ? data.comment
          : defaultCartData.comment;
    }

    // Cart Options
    if (
      typeof data.deliveryAddressId === "string" ||
      defaultCartData.deliveryAddressId
    ) {
      cartOptions.deliveryAddressId =
        typeof data.deliveryAddressId === "string"
          ? data.deliveryAddressId
          : defaultCartData.deliveryAddressId;
    }
    /*if ( //Disabled because currently unused
        typeof data.invoiceAddressId === "string" ||
        defaultCartData.invoiceAddressId
      ) {
      cartOptions.invoiceAddressId =
        typeof data.invoiceAddressId === "string"
          ? data.invoiceAddressId
          : defaultCartData.invoiceAddressId;
    }*/
    if (typeof data.deliveryType === "string" || defaultCartData.deliveryType) {
      cartOptions.deliveryType =
        typeof data.deliveryType === "string"
          ? data.deliveryType
          : defaultCartData.deliveryType;
    }
    /*if ( //Disabled because currently unused
      typeof data.paymentType === "string" ||
      defaultCartData.paymentType
    ) {
      cartOptions.paymentType =
        typeof data.paymentType === "string"
          ? data.paymentType
          : defaultCartData.paymentType;
    } */
    requestBody.cartOptions = cartOptions;
    
    const request = async () => {
      if (typeof data.callback === "function") {
        data.callback({ status: "updating" });
      }

      let response = undefined;
      try {
        response = await apiserver.cart.updateCartData(requestBody);
      } catch (e) {
        console.log({ e });
        if (typeof data.callback === "function") {
          data.callback({ status: "error-update" });
        }
        return;
      }

      if (typeof data.callback === "function") {
        data.callback({ status: "updated" });
      }
      dispatch(setDefaultCartData(response.data));
    };

    if (data.commit === true) {
      await request();
    } else {
      dispatch(
        setDefaultCartData({
          ...defaultCartData,
          ...data,
          changed: true,
        })
      );

      clearTimeout(meta.updateCartTimer);
      dispatch(
        updateMeta({
          updateCartTimer: setTimeout(async () => {
            await request();
          }, meta.updateCartIntervall),
        })
      );
    }
  };
}
export function updateDefaultCart(query) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const cartData = state.cartData;
    try {
      await apiserver.cart.setDefaultCart(query);
      dispatch(fetchShoppingCarts());
    } catch (e) {
      console.log({ e });
      return;
    }
    dispatch(fetchShoppingCarts());
    const newDefaultCart = cartData.filter(cart => cart.default === true);
    dispatch(fetchDefaultShoppingCart(newDefaultCart.id));
  };
}
export function updateShoppingCartPosition(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCartData = state.defaultCartData;
    const meta = state.meta;
    const updatedCartData = { ...defaultCartData };
    const currentPositionIndex = updatedCartData.cartPositions.findIndex(position => position.id === data.positionId);
    updatedCartData.cartPositions[currentPositionIndex] = {
      ...updatedCartData.cartPositions[currentPositionIndex],
      ...data.posData,
    };
    const updatedCurrentPosition = {
      ...updatedCartData.cartPositions[currentPositionIndex],
    };

    const updateCartRequesObj = {
      positionId: updatedCurrentPosition.id,
      quantity: updatedCurrentPosition.quantity || 1,
      commission: updatedCurrentPosition.commission,
      comment: updatedCurrentPosition.comment,
    };

    const request = async () => {
      if (typeof data.callback === "function") {
        data.callback({ status: "updating" });
      }

      let response = undefined;
      try {
        response = await apiserver.cart.updateCartPosition(updateCartRequesObj);
      } catch (e) {
        console.log({ e });
        if (typeof data.callback === "function") {
          data.callback({ status: "error-update" });
        }
        return;
      }

      if (typeof data.callback === "function") {
        data.callback({ status: "updated" });
      }
      const responsePosition = response.data.cartPositions.find(
        (position) => position.id === updatedCurrentPosition.id
      );
      //dispatch(setDefaultCartData(response.data)); //Response is Empty
      updatedCartData.cartPositions[currentPositionIndex] = responsePosition;

      // Extra step for group/configurator positions
      if (updatedCurrentPosition.positionType === "Configurator") {
        updatedCartData.cartPositions.forEach((position, index) => {
          if (position.groupId === updatedCurrentPosition.id) {
            updatedCartData.cartPositions[index] =
              response.data.cartPositions.find(
                (position) =>
                  position.id === updatedCartData.cartPositions[index].id
              );
          }
        });
      }

      dispatch(
        setDefaultCartData({
          ...updatedCartData,
          totalProductPriceNet: response.data.totalProductPriceNet,
          totalProductPriceGross: response.data.totalProductPriceGross,
          totalPriceNet: response.data.totalPriceNet,
          totalPriceGross: response.data.totalPriceGross,
          minimumOrderValue: response.data.minimumOrderValue,
          shippingCosts: response.data.shippingCosts,
          shippingCostsGross: response.data.shippingCostsGross,
        })
      );
    }

    if (data.posData.commit === true) {
      await request();
      
    } else {
      updatedCartData.cartPositions[currentPositionIndex].changed = true;
      dispatch(setDefaultCartData(updatedCartData));

      clearTimeout(meta.updatePositionTimer);
      dispatch(
        updateMeta({
          updatePositionTimer: setTimeout(async () => {
            await request();
          }, meta.updateCartIntervall),
        })
      );
    }
  };
}

export function emptyShoppingCartPositions(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCartData = state.defaultCartData;
    if (typeof data.callback === "function") {
      data.callback({ status: "deleting" });
    }

    try {
      await apiserver.cart.clearCartData({ "cartId": data.cartId });
    } catch (e) {
      console.log({ e });
      if (typeof data.callback === "function") {
        data.callback({ status: "error-delete" });
      }
      return;
    }
    if (typeof data.callback === "function") {
      data.callback({ status: "deleted" });

    }
    dispatch(
      setDefaultCartData({ ...defaultCartData, cartPositions: [] })
    );
    dispatch(fetchDefaultShoppingCart());
  };
}

export function deleteShoppingCart(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const cartData = state.cartData;
    if (typeof data.callback === "function") {
      data.callback({ status: "deleting" });
    }
    
    try {
      await apiserver.cart.deleteCartData(data.cartId);
    } catch (e) {
      console.log({ e });
      if (typeof data.callback === "function") {
        data.callback({ status: "error-delete" });
      }
      return;
    }
    if (typeof data.callback === "function") {
      data.callback({ status: "deleted" });

      const newCartData = cartData.filter(cart => cart.id !== data.id);
      dispatch(
        setCartData(newCartData)
      );
    }

    dispatch(fetchShoppingCarts());
  };
}

export function deleteShoppingCartPosition(data) {
  return async (dispatch) => {
    if (typeof data.callback === "function") {
      data.callback({ status: "deleting" });
    }
    let response = undefined;
    try {
      response = await apiserver.cart.deleteCartPosition(data.positionId);
    } catch (e) {
      console.log({ e });
      if (typeof data.callback === "function") {
        data.callback({ status: "error-delete" });
      }
      return;
    }

    if (typeof data.callback === "function") {
      data.callback({ status: "deleted" });
    }

    dispatch( setDefaultCartData(response.data) );
  };
}
export function addShoppingCartPosition(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCartData = state.defaultCartData;

    if (typeof data.callback === "function") { data.callback({ status:"loading" }); }
    
    let response = undefined;
    try {
      response = await apiserver.cart.submitCartPosition({
        cartId: defaultCartData.id,
        productDimensionId: data.productDimensionId,
        quantity: data.quantity,
        changedLength: data.changedLength,
        tolerance: data.tolerance,
        isThreeOne: data.isThreeOne,
        comment: data.comment,
        commission: data.commission
      });
    } catch (e) {
      console.log({ e });
      
      if (typeof data.callback === "function") {
        data.callback({ status:"error" });
      }

      return;
    }

    dispatch(setDefaultCartData(response.data));

    if (typeof data.callback === "function") {
      data.callback({ status: "success" });
    }
  };
}

export function addShoppingCartPositionDirect(data) {
  return async (dispatch) => {
    let response = undefined;
    try {
      response = await apiserver.cart.submitCartPositionDirect({
        abmessungsNr: data.abmessungsNr,
        quantity: data.quantity,
      });
    } catch (e) {
      console.log({ e });
      
      if (typeof data.callback === "function") {
        data.callback({ status: "error" });
      }
      return;
    }

    dispatch(setDefaultCartData(response.data));

    if (typeof data.callback === "function") {
      data.callback({ status: "success" });
    }
  };
}
export function addConfiguratorShoppingCartPosition(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCartData = state.defaultCartData;

    if (typeof data.callback === "function") { data.callback({ status:"loading" }); }
    
    let response = undefined;
    try {
      response = await apiserver.cart.submitConfiguratorCartPosition({
        optionalCartId: defaultCartData.id,
        articles: data.articles,
        easyKatConfig: data.easyKatConfig,
        configurationName: data.name
      });
    } catch (e) {
      console.log({ e });
      
      if (typeof data.callback === "function") {
        data.callback({ status:"error" });
      }

      return;
    }

    dispatch(setDefaultCartData(response.data));

    if (typeof data.callback === "function") {
      data.callback({ status: "success" });
    }
  };
}
export function replaceConfiguratorCartPosition(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCartData = state.defaultCartData;

    if (typeof data.callback === "function") {
      data.callback({ status: "loading" });
    }

    let response = undefined;
    try {
      response = await apiserver.cart.submitConfiguratorCartPosition({
        optionalCartId: defaultCartData.id,
        articles: data.articles,
        easyKatConfig: data.easyKatConfig,
        configurationName: data.name,
        replacePositionId: data.replacePositionId,
        comment: data.comment,
        commission: data.commission,
      });
    } catch (e) {
      console.log({ e });

      if (typeof data.callback === "function") {
        data.callback({ status: "error" });
      }

      return;
    }

    dispatch(setDefaultCartData(response.data));

    if (typeof data.callback === "function") {
      data.callback({ status: "success" });
    }
  };
}

export function getConfigurationByGroupId(groupId) {
  return async (dispatch) => {
    dispatch(updateMeta({ configuration: {} }));
    let response = undefined;
    try {
      response = await apiserver.cart.getConfiguration(groupId);
    } catch (e) {
      console.log({ e });
      return;
    }

    dispatch(updateMeta({ configuration: { id: groupId, data:response.data} }));
  };
}
export function replaceShoppingCartPosition(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCartData = state.defaultCartData;

    if (typeof data.callback === "function") { data.callback({ status:"loading" }); }
    
    let response = undefined;
    try {
      response = await apiserver.cart.replaceCartPosition({
        cartId: defaultCartData.id,
        positionId: data.positionId,
        productDimensionId: data.productDimensionId,
        quantity: data.quantity,
        changedLength: data.changedLength,
        tolerance: data.tolerance,
        isThreeOne: data.isThreeOne,
      });
    } catch (e) {
      console.log({ e });
      
      if (typeof data.callback === "function") {
        data.callback({ status:"error-replacePos" });
      }

      return;
    }

    dispatch(setDefaultCartData(response.data));

    if (typeof data.callback === "function") {
      data.callback({ status: "success-replacePos" });
    }
  };
}
/* export function getShippingCosts(data) {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const defaultCartData = state.defaultCartData;
    const meta = state.meta;
    let response = undefined;
    try {
      // response = await apiserver.order.getShippingCosts({
      //   cartId: defaultCartData.id,
      //   deliveryAddressId: data?.deliveryAddressId || meta.deliveryAddressId,
      // });
      response = await apiserver.cart.updateCartData({
        cartId: defaultCartData.id,
        cartOptions: {
          deliveryAddressId: data?.deliveryAddressId ||  meta.deliveryAddressId,
          invoiceAddressId: data?.invoiceAddressId || meta.invoiceAddressId,
          deliveryType: data?.deliveryType || meta.deliveryMethod,
          paymentType: data?.paymentType || meta.paymentMethod
        }
      });
    } catch (e) {
      console.log({ e });
      return;
    }

  dispatch(updateMeta(response.data));
  dispatch(
    setDefaultCartData({
      ...defaultCartData,
      ...data,
      changed: true,
    })
  );
  };
} */

export function downloadCadZip() {
  return async (dispatch, getState) => {
    const state = getState()[getReducerName()];
    const cadSelection = [...new Set(state.meta.cadSelection)];
    try {
      await apiserver.resources.getCadZip(cadSelection);
    } catch (e) {
      console.log({ e });
      return;
    }
  }
}

export function downloadCartExcel() {
  return async (dispatch ,getState) => {
    const state = getState()[getReducerName()];
    const { id, name, created } = state.defaultCartData;
    try {
      await apiserver.cart.exportPartsList({
        cartId: id,
        name: name || created
      });
    } catch (e) {
      console.log({ e });
      return;
    }
  }
}
export function downloadConfigurationStepFile(data) {
  return async (dispatch) => {
    try {
      data?.callback({ statusStepDl: "loading"});
      await apiserver.cart.getConfigurationStepFile(data.groupId);
    } catch (e) {
      console.log({ e });
      data?.callback({ statusStepDl: "error" });
      return;
    }
    data?.callback({ statusStepDl: "success" });
  }
}


export function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOP_FETCH_META:
      return {
        ...state,
        meta: action.data,
      };
    case SHOP_SET_CART_DATA:
      return {
        ...state,
        cartData: action.data,
      };
    case SHOP_SET_DEFAULT_CART_DATA:
      return {
        ...state,
        defaultCartData: action.data,
      };
    case SHOP_SET_RECENT_ORDER:
      return {
        ...state,
        recentOrder: action.data,
      };
    default:
      return state;
  }
}

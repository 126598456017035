import axios from "../axios";
import { initApi, BASE_URL } from "../initApi";

// ----------------------- APP - PRODUCT - GET PRODUCT
export async function getProduct(productId) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/product/${productId}`);
  });
}
// ----------------------- APP - PRODUCT - GET PRODUCT By NUMBER
export async function getProductsByNumber(params) {
  return initApi().then(() => {
    return axios.get(`${BASE_URL}/api/product/by-product-nr?${params}`);
  });
}

// ----------------------- APP - PRODUCT - SEARCH PRODUCT
export async function searchProduct(query) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/product/search`, query);
  });
}
// ----------------------- APP - PRODUCT - Auto Complete
export async function autoComplete(searchStr) {
  return initApi().then(() => {
    return axios.get(
      `${BASE_URL}/api/product/auto-complete?searchString=${searchStr}`
    );
  });
}

// ----------------------- APP - PRODUCT - FILTER PRODUCT
export async function filterProduct(query) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/product/filter`, query);
  });
}

// ----------------------- APP - PRODUCT - SEARCH-SIMPLE PRODUCT
export async function searchSimpleProduct(query) {
  return initApi().then(() => {
    return axios.post(`${BASE_URL}/api/product/search-simple`, query);
  });
}
